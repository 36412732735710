import '../scss/application.scss'
import 'bootstrap'

// Used for DELETE links and simple confirmation dialogs
import Rails from 'rails-ujs'
Rails.start();

require.context('../images', true)

// FIXME: This is an ugly patch to use GA4 inside ncs. Updating this project to ncs-1.8-hotfix breaks multiselect.
// Updating to dev implies updating rails and several other problems.
// Remove this hack when we update ncs version for this project.
window.document.addEventListener('click', (event) => {
  const resultType = document.querySelector('#search-form > form > div:nth-child(1) > div.block-wrapper.col-md-9.mb-3 > fieldset > div > div:nth-child(1) > div > select')
  const searchButton = document.querySelector('#search-form > form > div.d-flex.justify-content-between.mt-3 > div > button:nth-child(1)')
  const downloadButton = document.querySelector('#search-form > div > div.kwic > div:nth-child(1) > div.d-flex.ml-auto.align-self-end.mt-3 > button')
  const navigationDownloadButton = document.querySelector('body > div:last-child button.btn')

  if (event.target === searchButton) {
    window.gtag('event', 'page_view', { page_path: `/${resultType.value}/search` })
  } else if (event.target === downloadButton) {
    // Generic result download
    window.gtag('event', 'page_view', { page_path: `/${resultType.value}/download` })
    // Navigation download
  } else if (event.target === navigationDownloadButton) {
    window.gtag('event', 'page_view', { page_path: `/navigation/download` })
  } else if (event.target.closest('.page-form') ) {
    // Navigate with the "Go to page" button
    window.gtag('event', 'page_view', { page_path: `/${resultType.value}/navigation` })
  } else if (event.target.closest('.page-link')) {
    // Navigate clicking on pagination links
    window.gtag('event', 'page_view', { page_path: `/${resultType.value}/navigation` })
  } else if (event.target.closest('.navigation-row-context-link')) {
    // Click on texts row
    window.gtag('event', 'page_view', { page_path: `/text` })
  } else if (event.target.closest('.kwic-row-context-link')) {
    // Click on KWIC row
    window.gtag('event', 'page_view', { page_path: `/context` })
  }
})
